<template>
  <introduction-template
    :service-name="$route.name"
    :title="$t(`button['natural language processing']`)"
    :description="$t(`description['With our powerful Natural Language Processing(NLP) technologies']`)"
    :particle-options="particleOptions"
    :features="servicesCards"
    service-abbrev="NLP"
  >
    <template slot="banner">
      <banner class="animate-svg-blocks" />
    </template>
  </introduction-template>
</template>
<script>
import iconNER from '@/assets/images/icons/services/introduction/nlp/ner.svg';
import iconSEM from '@/assets/images/icons/services/introduction/nlp/sem.svg';
import iconRCH from '@/assets/images/icons/services/introduction/nlp/rch.svg';
import iconTSM from '@/assets/images/icons/services/introduction/nlp/tsm.svg';
import iconCLS from '@/assets/images/icons/services/introduction/nlp/cls.svg';
import iconPOS from '@/assets/images/icons/services/introduction/nlp/pos.svg';
import iconDIA from '@/assets/images/icons/services/introduction/nlp/dia.svg';
import Banner from '../../components/BannerBlocks/NLP.vue';
import IntroductionTemplate from '../../components/IntroductionTemplate/Index.vue';

export default {
  components: { IntroductionTemplate, Banner },
  data() {
    return {
      particleOptions: { color: '#FC9C0D', linesColor: '#C97A04' },
      servicesCards: [
        {
          icon: iconNER,
          title: this.$t("button['named entity recognition']"),
          languages: [this.$consts.lang.EN, this.$consts.lang.AR],
          introduction: this.$t(
            'description["Being one of the most widely used services in natural language processing"]',
          ),
          link: 'named-entity-recognition',
        },
        {
          icon: iconSEM,
          title: this.$t("button['sentiment analysis']"),
          languages: [this.$consts.lang.EN, this.$consts.lang.AR],
          introduction: this.$t(
            'description["A powerful tool to detect emotions by analyzing any text content"]',
          ),
          link: 'sentiment-analysis',
        },
        {
          icon: iconRCH,
          title: this.$t("button['reading comprehension']"),
          languages: [this.$consts.lang.EN, this.$consts.lang.AR],
          introduction: this.$t(
            'description["An intelligent and automatic way of answering questions by processing a piece of unstructured text"]',
          ),
          link: 'reading-comprehension',
        },
        {
          icon: iconTSM,
          title: this.$t("button['text summarization']"),
          languages: [this.$consts.lang.EN, this.$consts.lang.AR],
          introduction: this.$t(
            'description["Automatically condense a piece of text to a shorter version"]',
          ),
          link: 'text-summarization',
        },
        {
          icon: iconCLS,
          title: this.$t("button['document classification']"),
          languages: [this.$consts.lang.EN, this.$consts.lang.AR],
          introduction: this.$t(
            'description["Automatically classify articles or any other text data into specific topics"]',
          ),
          link: 'document-classification',
        },
        {
          icon: iconPOS,
          title: this.$t("button['part of speech tagging']"),
          languages: [this.$consts.lang.EN, this.$consts.lang.AR],
          introduction: this.$t(
            'description["One of the most fundamental and foundational services in processing any natural language content"]',
          ),
          link: 'pos',
        },
        {
          icon: iconDIA,
          title: this.$t("button['arabic diacritization']"),
          languages: [this.$consts.lang.AR],
          introduction: this.$t(
            'description["The process of restoring the diacritical marks (short vowels) is known as diacritization. Diacritics are very important for readability and understandability of texts. Our Arabic Diacritization service restores all necessary diacritics while maintaining the neat and clean format of writing."]',
          ),
          link: 'arabic-diacritization',
        },
      ],
    };
  },
};
</script>
